# # вывод в консоль одного переданного параметра
	#
	# использование :
	#   con параметр
	#
con = (input) ->
	console.log('________________________');
	console.log(input);
	console.log('________________________');
	#
	return
# ==========================================

# # вывод в консоль массива переданных параметров
	#
	# использование :
	#   conar(['a', 'b'])
	#
conar = (input) ->
	# преобразуем массив в строку с разделителем |
	string = input.join(' | ')
	console.log('________________________');
	console.log(string);
	console.log('________________________');
	#
	return
# ==========================================

